
export default {
    data() {
        return {
            sideNavOpen: true,
            topNavHeight: 0
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    mounted() {
        this.$nuxt.$on("toggleSideNav", () => {
            this.sideNavOpen = !this.sideNavOpen;
        });
        this.topNavHeight = this.$getTopNavHeight();
    }
};
