import Vue from 'vue';
export const state = () => ({
    campusLessons: [],
    activities: [],
    customActivities: [],
    resources: [],
    search: {
        page: '',
        options: {
            text: '',
            kinds: [],
            grades: [],
            topics: [],
            plugged: ''
        },
        items: {},
        more: false,
        total: 0
    },
    tutors: {},
    tutorsCount: 0,
    tutorVotes: [],
    searchedTutors: {},
    tutorsTags: []
});

const addByIndex = (list, item, unshift = false) => {
    const index = list.findIndex(o => o.id === item.id);
    if (index >= 0) {
        list[index] = item;
    } else if (unshift) {
        list.unshift(item);
    } else {
        list.push(item);
    }
};

const refreshState = (state, name) => {
    state[name] = [...state[name]];
};

export const mutations = {
    updateCampusLessons(state, lessons) {
        if (state.campusLessons.length <= 1) {
            state.campusLessons = lessons;
        } else {
            state.campusLessons = [...state.campusLessons, ...lessons];
        }
    },
    fullCampusLesson(state, lesson) {
        const lessons = state.campusLessons;
        const index = lessons.findIndex(o => o.id === lesson.id);
        if (index >= 0) {
            lessons[index] = { ...lesson, full: true };
        } else {
            lessons.push({ ...lesson, full: true });
        }
    },
    updateActivities(state, activities) {
        if (state.activities.length <= 1) {
            state.activities = activities;
        } else {
            state.activities = [...state.activities, ...activities];
        }
    },
    updateActivity(state, activity) {
        const index = state.activities.findIndex(o => o.id === activity.id);
        if (index >= 0) {
            const updatedActivity = { ...state.activities[index], ...activity };
            Vue.set(state.activities, index, updatedActivity);
        }
    },
    fullActivity(state, activity) {
        const bank = state.activities;
        const index = bank.findIndex(o => o.id === activity.id);
        if (index >= 0) {
            bank[index] = { ...activity, full: true };
        } else {
            bank.push({ ...activity, full: true });
        }

    },
    tutors(state, data) {
        if (!state.tutors[data.page]) {
            state.tutors[data.page] = data.tutors;
        }
        state.tutors = { ...state.tutors };
    },
    searchedTutors(state, data) {
        const { query, tutors, page, tags } = data;
        const key = `${query}_${page}_${tags.join(",")}`;
        if (!state.searchedTutors[key]) {
          state.searchedTutors[key] = tutors;
        } else {
          state.searchedTutors[key] = tutors;
        }
        state.searchedTutors = { ...state.searchedTutors };
    },
    tutorsCount(state, tutorsCount) {
        state.tutorsCount = tutorsCount;
    },
    updateTutorVote(state, data) {
        const index = state.tutors[data.currentPage]?.length && state.tutors[data.currentPage].findIndex(o => o.id === data.tutorId);
        if (index >= 0) {
            let tutor = state.tutors[data.currentPage][index];
            tutor.user_voted = data.voted;
            tutor.vote_count = data.votes;
        }
    },
    setSearch(state, search) {
        const options = {
            text: search.options.text || '',
            kinds: search.options.kinds || [],
            grades: search.options.grades || [],
            topics: search.options.topics || [],
            plugged: search.options.plugged || ''
        };
        state.search = {
            page: search.page,
            options: options,
            items: search.items,
            more: search.more,
            total: search.total
        };
    },
    updateSearch(state, updates) {
        state.search = { ...state.search, ...updates };
    },
    updateSearchItems(state, item) {
        const results = state.search.items[item.kind];
        const index = results.findIndex(o => o.id == item.id);
        if (index >= 0) {
            const newItem = { ...results[index], ...item };
            Vue.set(state.search.items[item.kind], index, newItem);
        }
    },
    addSearchItems(state, items) {
        const results = state.search.items[items.kind];
        if (!results.length) {
            state.search.items[items.kind] = items.data;
        } else {
            state.search.items[items.kind] = [...results, ...items.data];
        }
    },
    customActivities(state, activities) {
        state.customActivities = activities;
    },
    customActivity(state, activity) {
        addByIndex(state.customActivities, activity);
        refreshState(state, 'customActivities');
    },
    resources(state, resources) {
        state.resources = resources;
    },
    tutorsTags(state, tags) {
        state.tutorsTags = tags;
    }
};