export const state = () => ({
  modal: {
    isOpen: false,
    isLoading: false
  },
  items: {
    list: [],
    currentIndex: -1
  },
  context: {
    activity: {
      id: null,
      type: null,
      label: null,
      name: null
    },
    section: {
      id: null,
      name: null
    },
    lesson: {
      id: null,
      name: null
    }
  }
})

export const mutations = {
  openModal(state, { studentId, activity, section, lesson, submissions = [] }) {
    state.modal.isOpen = true
    state.modal.isLoading = true;
    Object.assign(state.context, {
      section: { id: section.id, name: section.name },
      lesson: { id: lesson.id, name: lesson.name },
      activity: {
        id: activity.id,
        type: activity.type,
        name: activity.name,
        label: activity.label
      }
    });

    state.items.list = submissions;
    state.items.currentIndex = submissions.findIndex(submission => 
      submission.student.id === studentId
    );
  },

  closeModal(state) {
    state.modal.isOpen = false;
    state.modal.isLoading = false;
    state.context.section.id = null;
    state.context.section.name = null;
    state.context.lesson.id = null;
    state.context.lesson.name = null;
    state.context.activity.id = null;
    state.context.activity.type = null;
    state.context.activity.name = null;
    state.items.currentIndex = -1;
    state.items.list = [];
  },

  updateCurrentIndex(state, index) {
    state.items.currentIndex = index;
    state.modal.isLoading = true; // Set loading when changing submissions
  },

  setLoading(state, loading) {
    state.modal.isLoading = loading; 
  },
}

export const actions = {
  next({ commit, state, getters }) {
    if (!getters.hasNext) return;
    commit('setLoading', true);
    commit('updateCurrentIndex', state.items.currentIndex + 1);
  },

  prev({ commit, state, getters }) {
    if (!getters.hasPrev) return;
    commit('setLoading', true);
    commit('updateCurrentIndex', state.items.currentIndex - 1);
  }
}

export const getters = {
  hasNext: state => state.items.currentIndex < state.items.list.length - 1,
  hasPrev: state => state.items.currentIndex > 0,
  submission: state => state.items.list[state.items.currentIndex] || null,
  submissionNumber: state => state.items.currentIndex + 1,
  totalSubmissions: state => state.items.list.length  
}