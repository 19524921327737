import { render, staticRenderFns } from "./simple.vue?vue&type=template&id=876145ce"
import script from "./simple.vue?vue&type=script&lang=js"
export * from "./simple.vue?vue&type=script&lang=js"
import style0 from "./simple.vue?vue&type=style&index=0&id=876145ce&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Nav: require('/opt/buildhome/repo/components/Nav.vue').default})
