export default (context, inject) => {
    inject('saveLocal', (key, data) => {
        try {
            localStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
            console.error(e);
            localStorage.clear();
            localStorage.setItem(key, JSON.stringify(data));
        }
    });
    inject('copy', data => {
        return JSON.parse(JSON.stringify(data));
    });
    inject('isEqual', (object1, object2) => {
        const isObject = (obj) => obj != null && typeof obj === 'object';
        const deepEqual = (first, second) => {
            if (!first || !second) return false;
            const keys1 = Object.keys(first);
            const keys2 = Object.keys(second);

            if (keys1.length !== keys2.length) return false;

            for (const key of keys1) {
                const val1 = first[key];
                const val2 = second[key];
                const areObjects = isObject(val1) && isObject(val2);
                if (areObjects && !deepEqual(val1, val2) || !areObjects && val1 !== val2) {
                    return false;
                }
            }

            return true;
        };
        return deepEqual(object1, object2);
    });
    inject('getLocal', key => {
        try {
            const data = JSON.parse(localStorage.getItem(key));
            if (Date.now() > data.exp) {
                throw new Error("Expired");
            }
            return data;
        } catch (e) {
            return null;
        }
    });
    inject('clearLocal', key => {
        localStorage.removeItem(key);
    });
    inject('success', message => {
        window.$nuxt.$bvToast.toast(message, {
            title: "Success",
            variant: "success",
            solid: true
        });
    });
    inject('error', (props = { title: '', message: '' }) => {
        if (typeof (props) === 'string') {
            props = { message: props };
        }
        window.$nuxt.$bvToast.toast(props.message, {
            title: "Error",
            variant: "danger",
            solid: true,
            noAutoHide: true,
            ...props
        });
    });
    inject('info', (props = { title: '', message: '' }) => {
        if (typeof (props) === 'string') {
            props = { message: props };
        }
        window.$nuxt.$bvToast.toast(props.message, {
            title: 'Information',
            variant: "info",
            solid: true,
            noAutoHide: true,
            appendToast: true,
            ...props
        });
    });
    inject('clipboard', async text => {
        try {
            await navigator.clipboard.writeText(text).then(() => true);
        } catch (e) {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            textarea.style.top = '0';
            textarea.style.left = '0';
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            const result = document.execCommand('copy');
            document.body.removeChild(textarea);
            return result;
        }
    });
    inject('getCurrentSchoolYear', () => {
        const now = new Date(Date.now());
        const month = now.getMonth();
        const year = month < 6 ? now.getFullYear() - 1 : now.getFullYear();
        return year;
    });
    inject('getVariable', (variable) => {
        return context.env[variable];
    });
    inject('getTopNavHeight', (variable) => {
        let elem = document.getElementById("top-nav");
        return elem ? elem.getBoundingClientRect().height : 0;
    });
    inject("isDevMode", process.env.STAGE !== "prod");
};
