import { render, staticRenderFns } from "./NavCommunity.vue?vue&type=template&id=77a91e89&scoped=true"
import script from "./NavCommunity.vue?vue&type=script&lang=js"
export * from "./NavCommunity.vue?vue&type=script&lang=js"
import style0 from "./NavCommunity.vue?vue&type=style&index=0&id=77a91e89&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77a91e89",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/opt/buildhome/repo/components/Logo.vue').default,CommunityNavDropdown: require('/opt/buildhome/repo/components/community/NavDropdown.vue').default,Help: require('/opt/buildhome/repo/components/Help.vue').default,Notifications: require('/opt/buildhome/repo/components/Notifications.vue').default})
